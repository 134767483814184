export const useRegions = async () => {
  // const { data, error } = await useFetch('/api/adopt/external-data')
  const { data, error } = await useFetch('/api/region/local-data')

  /* The above useFetch is a syntactic sugar of the below useAsyncData & $fetch combo */
  // const { data, error } = await useAsyncData('regions', async () => {
  //   return await $fetch('/api/store/local-data')
  // })

  if (error.value) {
    throw createError({
      ...error.value,
      statusMessage: `Couldn't fetch region profiles.`,
    })
  }

  const { allRegions, someRegions } = data.value

  return { allRegions, someRegions }
}
