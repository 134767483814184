<script setup lang="ts">
  const { allRegions: regions } = await useRegions()
</script>
<template>
      <div class="container mx-auto px-4">
        <div
          class="-mx-4 flex flex-col flex-wrap items-center justify-center mb-4"
        >
          <div class="px-4 text-center w-full">
            <h3 class="capitalize text-primary-600">Book Your Party</h3>
            <p class="mb-4">
              Select Your Location To Book
            </p>
          </div>
          <!-- <div class="px-4 text-center w-full">
            <BaseButton size="xl" to="/adopt" color="secondary"><span>Meet Them All ></span>
            </BaseButton>
          </div> -->
        </div>
        <!-- <div class="flex flex-wrap -mx-4 justify-center">
          <RegionCard v-for="(region, index) in regions" :key="index" v-bind="region" />
        </div> -->

        <div class="flex flex-col md:grid md:grid-cols-3 gap-3">
          <RegionCard v-for="(region, index) in regions" :key="index" v-bind="region" />

      <!-- <div class="relative rounded overflow-hidden" >
          <img src="https://images.squarespace-cdn.com/content/v1/611a8a412522b80f4cc33d0b/fe0749a4-727c-405c-a4f6-dfa1bfb2e7ea/aurora-kreativ-UN4cs4zNCYo-unsplash.jpg?format=1500w" alt="Hanging Planters" class="w-full">
        <p
      class="cursor-pointer absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-2xl text-center text-white font-roboto font-medium group-hover:bg-opacity-60 transition">
      Hanging Planters
    </p> -->


  <!-- </div> -->
    </div>
  </div>
</template>
<style scoped></style>
